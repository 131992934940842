<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <div style="display: flex; align-items: center">
              <div
                style="
                  flex: 1;
                  text-align: left;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                "
              >
                {{ mySpecial ? "我的专题" : "专题课程" }}
              </div>
            </div>
          </a-col>

          <a-row>
            <a-col :span="24">
              <!-- 使用组件 -->
              <my-special-class :isMySpecial="isMySpecial"></my-special-class>
            </a-col>
          </a-row>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// 引入专题课程数据列表组件
import MySpecialClass from "./special-class-list.vue";
import { mapMutations, mapState } from "vuex";
export default {
  props: { isMySpecial: { type: Boolean, default: false } },
  data() {
    return { mySpecial: false };
  },
  created() {
    console.log(this.$route.query);
    this.mySpecial = this.$route.query.isMySpecial;
  },
  components: {
    MySpecialClass,
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
};
</script>

<style lang="scss" scoped></style>
