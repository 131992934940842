<template>
  <!-- 专题课程内容 -->
  <div class="content">
    <!-- 专题课程列表数据 -->
    <template>
      <!-- 课程标签选择 -->
      <el-tabs v-if="mySpecial" v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="我参加的" name="first"></el-tab-pane>
        <el-tab-pane label="必学专题" name="second"></el-tab-pane>
      </el-tabs>
      <el-tabs v-else v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="最新" name="first"></el-tab-pane>
        <el-tab-pane label="学习人数" name="second"></el-tab-pane>
        <el-tab-pane label="学习进度" name="third"></el-tab-pane>
      </el-tabs>
      <template v-if="empty">
        <a-row type="flex" justify="center" style="margin-bottom: 10px; margin-top: 10px; min-height: 400px">
          <el-empty :description="description"></el-empty>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="24" style="margin-top: 20px; min-height: 500px" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
          <a-col :span="12" :lg="6" v-for="(item, index) in listData" :key="item.id" @click="onItemClick(index)">
            <div style="
                                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
                                border-radius: 8px;
                                overflow: hidden;
                                margin-bottom: 24px;
                                cursor: pointer;
                              ">
              <div style="position: relative">
                <el-image style="width: 100%; aspect-ratio: 16/9" :src="item.imgUrl" fit="cover"></el-image>
                <!-- <template v-if="item.type <= 2">
              </template> -->
                <template>
                  <!-- 播放按钮 -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24" style="
                                      fill: rgba(255, 255, 255, 0.67);
                                      border-color: rgba(0, 0, 0, 0);
                                      border-width: bpx;
                                      border-style: undefined;
                                      position: absolute;
                                      top: 0;
                                      right: 0;
                                      bottom: 0;
                                      left: 0;
                                      margin: auto;
                                    " filter="drop-shadow(0px 0px 6px rgba(0,0,0,0.4))">
                    <path
                      d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z">
                    </path>
                  </svg>
                </template>
              </div>
              <div style="padding: 8px">
                <div class="u-line-2" style="
                                    font-size: 12px;
                                    color: #101010;
                                    font-weight: 400;
                                    height: 36px;
                                    margin-bottom: 8px;
                                  ">
                  {{ item.specialSubjectName ? item.specialSubjectName : "" }}
                </div>
                <div class="course-message">
                  <!-- 进度 -->
                  <el-progress :text-inside="true" :stroke-width="26"
                    :percentage="item.totalProgress > 1 ? Number(Math.floor(item.totalProgress)) : item.totalProgress === 0 ? 0 : item.totalProgress.toFixed(2)"
                    :color="progressBg" style="width: 30%; height: 100%; border-radius: 0"></el-progress>
                  <div class="total-course">
                    <span>{{ item.totalCount }}课时</span>
                  </div>
                </div>
                <div class="mt-8" style="
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 8px;
                                    justify-content: space-between;
                                  ">
                  <div style="display: flex; flex-direction: row; align-items: center">
                    <img :src="
                      item.departmentUrl && item.departmentUrl.length != 0
                        ? item.departmentUrl
                        : 'https://swlm.oss-cn-guiyang-gzdata-d01-a.res.gzdata.com.cn/uploadfile/20181119/1542612533988-cldefaultavatar.png'
                    " width="18px" height="18px" style="border-radius: 50%" />
                    <div style="
                                        font-size: 12px;
                                        color: #868686;
                                        font-weight: 400;
                                        margin-left: 6px;
                                        word-break: keep-all;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                      ">
                      {{ item.departmentName }}
                    </div>
                  </div>
                  <div style="
                                      font-size: 12px;
                                      color: #a8a8a8;
                                      word-break: keep-all;
                                      white-space: nowrap;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                    ">
                    {{ `${item.learnedCount}人已参加` }}
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
        <!-- 分页器 -->
        <a-row type="flex" justify="center" style="margin-bottom: 10px; margin-top: 10px">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-size="12" layout="total, prev, pager, next, jumper" :total="totalCount">
          </el-pagination>
        </a-row>
      </template>
    </template>
  </div>
</template>

<script>
// import { mapState } from "vuex";
export default {
  props: { isMySpecial: { type: Boolean, default: false } },
  data() {
    return {
      listData: [],
      shortType: 0,
      shortTypes: ["综合排序", "人气排序"],
      //   categoryList: [{ id: "", name: "全部" }],
      keyword: "",
      loading: true,
      empty: false,
      totalCount: 0, // 分页器总页数
      pageIndex: 1, // 当前页数
      pageSize: 12, // 每一页显示的数据总数
      description: "暂无数据",
      progressBg: "#37b6df", // 进度条背景色
      activeName: "first", // el-tabs数据
      learnProgress: 0, // 学习进度
      learnProgressList: [], // 学习进度数组
      mySpecial: false,
    };
  },
  watch: {
    keyword(newVal, val) {
      if (this.isEmpty(newVal)) {
        this.pageIndex = 1;
        this.getListData();
      }
    },
  },
  created() {
    console.log(this.$route.query);
    this.mySpecial = this.$route.query.isMySpecial;
    this.getListData();
  },
  mounted() {
    this.getListData();
  },
  computed: {
    // ...mapState(["sumProgress"]),
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getListData();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getListData();
    },
    // 点击进入课程详情页面
    onItemClick(index) {
      this.$router.push({
        path: "/specialclass/specialdetails",
        query: { id: this.listData[index].id },
      });

      // 将对应课程的学习时长和进度保存在本地存储中
      localStorage.setItem(
        "learnTime",
        JSON.stringify(this.listData[index].learnedTotalDuration)
      );
      // 将课程的发起者存入store仓库中
      this.$store.dispatch("set_departName", this.listData[index].departmentName);
      localStorage.setItem(
        "department",
        JSON.stringify(this.listData[index].departmentName)
      );
      this.$store.dispatch("set_num1", 0);
      console.log(this.listData[index].departmentName);
    },
    // 获取专题课程列表数据
    getListData() {
      this.loading = true;
      let params = {
        type: this.mySpecial ? this.shortType + 1 : this.shortType,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        keyword: this.keyword,
      };
      this.$HTTP
        .httpToken({
          url: this.mySpecial
            ? "/classroom/getMySpecialSubjectList"
            : "/classroom/getSpecialSubjectList",
          method: "post",
          data: params,
        })
        .then((res) => {
          console.log("课程", res);
          this.loading = false;
          if (res.code == 0 && res.data && res.data.length > 0) {
            this.empty = false;
            this.totalCount = res.data.length;
            this.listData = res.data;
            console.log(this.learnProgressList);
          } else {
            if (this.pageIndex == 1) {
              this.empty = true;
              this.description = "暂无数据";
            } else {
              this.empty = false;
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          if (this.pageIndex == 1) {
            this.empty = true;
            this.description = e;
          } else {
            this.empty = false;
          }
        });
    },
    // el-tabs方法占位
    // 点击el-tabs标签时
    handleClick(tab, event) {
      console.log(tab, event);
      this.shortType = Number.parseInt(tab.index);
      console.log("this.shortType", this.shortType);
      this.getListData();
    },
  },
};
</script>

<style lang="scss" scoped>
.course-cate {
  height: 40px;
}

.mt-8 {
  margin-top: 8px;
}

::v-deep .el-progress-bar__outer,
::v-deep .el-progress-bar__inner {
  border-radius: 8px;
}

.total-course {
  font-size: 12px;
  color: #101010;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #37b6df;
  margin-left: 2%;
}

.course-message {
  display: flex;
  align-content: center;
}

::v-deep .el-progress-bar__outer {
  background: #3e8ccd;
}

::v-deep .u-line-2 {
  text-align: left;
}
</style>
